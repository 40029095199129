import React from 'react';
import { Link } from 'gatsby';

import './HomePage.scss';

const HomePage = ({ pages }) => {
  return (
    <div className='menuBlockWrapper'>
      <div>
        <h1>Souha Tahrani</h1>
        <h2>Architecte, PhD</h2>
      </div>
      <nav className='menuBlockContainer'>
        {pages
          .filter((page) => !page.parentId)
          .sort((a, b) => {
            if (a.menuOrder < b.menuOrder) {
              return -1;
            }
            if (a.menuOrder > b.menuOrder) {
              return 1;
            }
            return 0;
          })
          .map((p) => (
            <Link to={p.slug} key={`menuBlock_${p.id}`}>
              <div className={'menuBlock'}>{p.title}</div>
            </Link>
          ))}
      </nav>
    </div>
  );
};

export default HomePage;
