import React, { useContext, useEffect } from 'react';

import MainLayout from '../components/layouts/MainLayout';
import MainContext from '../context/MainContext';
import SEO from '../components/system/SEO';
import HomePage from '../components/layouts/HomePage';
import { usePageList } from '../hooks/usePageList';

const IndexPage = ({ location }) => {
  const [state, setState] = useContext(MainContext);
  const sitePages = usePageList();

  useEffect(() => {
    setState({ ...state, currentSection: 'home' });
  }, []);
  return (
    <MainLayout location={location} pages={sitePages}>
      <SEO />
      <HomePage pages={sitePages} />
    </MainLayout>
  );
};

export default IndexPage;
